import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';

export enum BootstrapGridBreakpoints  {
  XS = 576,
  XSlabel = 'XS',
  SM = 768,
  SMlabel = 'SM',
  MD = 992,
  MDlabel = 'MD',
  LG = 1200,
  LGLabel = 'LG',
  XL = 1400,
  XLlabel = 'XL',
  XXL = 99999,
  XXLlabel = 'XXL'
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  public subscribeToCurrentBootstrapBreakpoint(): any {
    return null;
  }

  public isBreakpointOrLower(breakpointLabel: keyof typeof BootstrapGridBreakpoints): Observable<boolean> {
    const maxWidth = this.calculateMaxWidth(breakpointLabel);
    const minWidth = this.getBreakpointValue(breakpointLabel);

    return this.breakpointObserver.observe(`(max-width: ${maxWidth}px)`).pipe(
      map((result: BreakpointState) => result.matches)
    );
  }

  private getBreakpointValue(breakpointLabel: keyof typeof BootstrapGridBreakpoints): number {
    const value = BootstrapGridBreakpoints[breakpointLabel];
    return typeof value === 'number' ? value : 0;
  }

  private calculateMaxWidth(breakpointLabel: keyof typeof BootstrapGridBreakpoints): number {
    const breakpointKeys = Object.keys(BootstrapGridBreakpoints) as (keyof typeof BootstrapGridBreakpoints)[];
    const currentIndex = breakpointKeys.indexOf(breakpointLabel);

    if (currentIndex === breakpointKeys.length - 1) {
      return Infinity;
    }

    const breakpoint = breakpointKeys[currentIndex];
    const breakpointValue = this.getBreakpointValue(breakpoint);

    
    return breakpointValue;
  }
}
